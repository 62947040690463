<template>
	<div class="allproducts">
	<indexPage :cate="cate" />
	</div>
</template>

<script>
import indexPage from '@/components/website/IndexPage.vue';

export default {
	name: 'allproducts',
	components:{indexPage},
	data(){
		return {
			cate:0
		}
	},
};
</script>

<style lang="scss" scoped>
</style>