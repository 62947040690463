<template>
  <div>
    <SiteHeader></SiteHeader>

    <div>
      <SiteSearch @tosearch="tosearch">
        <div class="selectBar">
          <el-dropdown placement="bottom-start" @command="typechange" size="sm">
            <span>
              {{ currentTypeText
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="menu in typecate" :command="menu.id">{{
                menu.name
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-dropdown
            placement="bottom-start"
            @command="brandchange"
            size="sm"
          >
            <span>
              Brand<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="menu in menucate" :command="menu.id">{{
                menu.ename
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-dropdown
            placement="bottom-start"
            size="sm"
            @command="seriesChange"
            v-if="currentCateList.length"
          >
            <span>
              Series<i class="el-icon-arrow-down el-icon--right"></i>
            </span>

            <el-dropdown-menu slot="dropdown">
              <div style="max-height: 400px; overflow-y: auto">
                <el-dropdown-item
                  v-for="menu in currentCateList"
                  :command="menu.id"
                  >{{ menu.ename }}</el-dropdown-item
                >
              </div>
            </el-dropdown-menu>
          </el-dropdown>

          <el-dropdown
            trigger="click"
            placement="bottom-start"
            @command="sortchange"
            size="sm"
          >
            <span>
              Sort By <span>{{ currentsort }}</span
              ><i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="menu in sortList" :command="menu.id">
                <i
                  :class="['bi', 'pe-1', menu.icon]"
                  role="img"
                  :aria-label="menu.icon"
                ></i>
                {{ menu.name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </SiteSearch>
      <div :class="{ productlist: true }">
        <div class="product" v-for="item in list" :key="item.id">
          <div class="pt bg2">
            <div class="ptbg" @click="todetail(item.id)">
              <img v-lazy="item.thumb" class="cover" alt="" />
              <img
                class="mask"
                v-if="item.mask === 2"
                src="@/assets/icon/sold out.png"
                alt=""
              />
              <img
                class="mask"
                v-if="item.mask === 3"
                src="@/assets/icon/closed.png"
                alt=""
              />
            </div>
          </div>
          <div class="pb bg2">
            <div class="title" v-html="item.title"></div>
            <div v-if="token" class="price">${{ item.price }}</div>
            <div v-else class="price">login to price</div>
            <div class="addcart">
              <div class="cart icontext">
                <i class="bi bi-cart-x"></i>
                <!-- <span v-if="item.mask === 1" @click="addtocart(item.id)">Add to Cart</span> -->
                <span class="text-anger" v-if="item.mask === 1" @click="action($event, item)"
                  >Add to Cart</span
                >
                <span v-if="item.mask === 2">Sold Out</span>
                <span v-if="item.mask === 3">Closed</span>
              </div>
              <div class="view icontext">
                <i class="bi bi-view-list"></i>
                <span @click="todetail(item.id)">View Item</span>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import SiteHeader from "@/components/website/SiteHeader.vue";
import SiteSearch from "@/components/website/SiteSearch.vue";
import Foot from "@/components/website/Foot.vue";
import { debounce } from "lodash";

import { brandbypid, brand, indexList, detail } from "@/api/index.js";

export default {
  name: "allproducts",
  data() {
    return {
      count: 12,
      //loading: false,
      search: "",
      piccover: "",
      heartBeat: false,
      sort: 0,
      currentType: 0, // 当前选中的类型
      currentsort: "Default",
      currentTypeText: "All Type",
      // currentTypeId:0,
      typecate: [
        {
          id: 0,
          name: "All Type",
        },
        {
          id: 1,
          name: "In Stock",
        },
        {
          id: 2,
          name: "Pre-Order",
        },
        {
          id: 3,
          name: "Custom",
        },
      ],
      sortList: [
        {
          id: 0,
          name: "Default",
          icon: "bi-ui-radios",
        },
        {
          id: 1,
          name: "Date,old to new",
          icon: "bi-sort-up",
        },
        {
          id: 2,
          name: "Date,new to old",
          icon: "bi-sort-down",
        },
        {
          id: 3,
          name: "Price,low to high",
          icon: "bi-sort-numeric-up",
        },
        {
          id: 4,
          name: "Price,high to low",
          icon: "bi-sort-numeric-down",
        },
        {
          id: 5,
          name: "Alphabetically,A-Z",
          icon: "bi-sort-alpha-up-alt",
        },
        {
          id: 6,
          name: "Alphabetically,Z-A",
          icon: "bi-sort-alpha-down-alt",
        },
      ],
      noMore: false,
      search: "",
      brand: null,
      rows: 15,
      perPage: 15,
      currentPage: 1,
      cid: null, //query
      list: [],
      menucate: [],
      currentCateList: [],
    };
  },
  components: {
    SiteHeader,
    SiteSearch,
    Foot,
  },
  props: {
    cate: {
      type: Number,
    },
  },

  watch: {
    //监听路由变化
    $route(to, from) {
      //this.noMore = false;
      this.cid = to.query.cid;
      this.currentPage = 1;
      this.list = [];
      // this.pid = to.query.pid
      // this.page = to.query.page
      this.getList();
    },
  },

  mounted() {
    window.addEventListener("scroll", debounce(this.handleScroll, 300));
    if (this.$route.query.cid) {
      this.cid = this.$route.query.cid;
    }
    this.getBrand();
    this.getList();
  },
  // 在 beforeDestroy 钩子中移除滚动事件监听器
  beforeDestroy() {
    window.removeEventListener("scroll", debounce(this.handleScroll, 300));
    // 其他清理逻辑...
  },
  computed: {
    disabled() {
      //return this.loading || this.noMore;
    },
    theme: {
      //0 light 1dark
      get() {
        return this.$store.state.theme;
      },
      set(v) {
        this.$store.commit("theme", v);
      },
    },

    isZh: {
      get() {
        return this.$i18n.locale === "zh";
      },
    },
    itemType: {
      get() {
        return this.$store.state.itemType;
      },
      set(v) {
        this.$store.commit("setItemType", v);
      },
    },
    token: {
      get() {
        return this.$store.state.user.token;
      },
    },
  },
  methods: {
    typechange(id) {
      this.currentTypeText = this.typecate.find((item) => item.id === id).name;
      this.currentType = id;
      this.getList();
    },
    // 防抖函数，用于限制事件触发频率
    debounce(func, wait) {
      let timeout;
      return function () {
        const context = this,
          args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    },
    handleScroll() {
      const bottomOffset = 200; // 距离底部的偏移量
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const clientHeight = document.documentElement.clientHeight;
      // 添加条件判断，只有在当前页面时才执行滚动加载逻辑
      if (this.$route.name === 'allproducts') {
        if (scrollHeight - scrollTop - clientHeight <= bottomOffset) {
          // 滚动到页面底部，加载更多数据
          if (!this.loading && !this.noMore) {
            this.load();
          }
        }
      }
    },

    load() {
      this.getList();
    },
    tosearch(search) {
      if (search) {
        this.searchlist(search);
      } else {
        this.getList();
      }
    },

    searchlist(search) {
      let params = { search: search };

      indexList(params).then((res) => {
        this.rows = res.data.total;
        this.list = res.data.list;
        this.$nextTick(() => {
          this.currentPage = res.data.page;
        });
      });
    },

    // 在 action 方法中去除对 ball 组件的调用
    action(ev, item) {
      // this.$nextTick(() => {
      //   this.piccover = item.thumb;
      // });
      this.addtocart(item.id);
      this.heartBeat = true;
    },

    // 求进入后
    afterEnter() {
      // console.log('成功加入购物车!');
    },
    tocart() {
      this.$router.push("/cart");
    },
    addtocart(id) {
      //实现加入购物车抛物线动画
      this.addCart(id);
    },
    addCart(id) {
      const detail = this.list.filter((item) => {
        return item.id === id;
      });
      // console.log('detail', detail)
      detail[0].count = detail[0].moq;
      this.$store.commit("user/addCart", detail[0]);
      this.$message.success("Item added to cart successfully.");
    },

    todetail(id) {
      this.$router.push("/detail/" + id);
    },

    changeCurrentPageRoute(page) {
      const ro = this.$route;
      let url = ro.path;
      url = url + "?";

      delete ro.query.page;
      if (ro.query.cid) {
        url = url + "cid=" + ro.query.cid + "&";
      }
      // if(ro.query.pid){
      // 	url = url+'pid='+ro.query.pid +'&'
      // }

      this.$router.push(url + "page=" + page);
    },

    // changpage(page) {
    // 	this.currentPage = page
    // 	// this.changeCurrentPageRoute(page)

    // },

    getList() {
      //this.loading = true; // 开始加载数据，设置状态为 true
      let params = {
        page: this.currentPage || 1,
        size: this.perPage,
        sort: this.sort,
        itemType: this.currentType,
      };
      params.category_id = this.$route.query.cid;

      if (this.$route.query.search) {
        params.search = this.$route.query.search;
      }
      // if(this.pid){
      // 	params.pid = this.pidp
      // }
      indexList(params).then((res) => {
        // this.rows = res.data.total
        if (res.data.list.length) {
          this.currentPage += 1;
          this.list = this.list.concat(res.data.list);
          //this.loading = false;
          //this.noMore = false;
        }
        // this.$nextTick(() => {
        // 	this.currentPage = res.data.page
        // })
      });
    },

    sortchange(id) {
      this.sort = id;
      this.currentsort = this.sortList[id].name;
      const ro = this.$route;
      let url = ro.path;
      url = url + "?";
      url = url + "sort=" + id;
      this.$router.push(url);
    },
    seriesChange(e) {
      this.cid = e;
      const ro = this.$route;
      let url = ro.path;
      url = url + "?";
      url = url + "cid=" + e;
      this.$router.push(url);
    },

    typechange(e) {
      this.currentTypeText = this.typecate.find((item) => item.id === e).name;
      this.currentType = e; // 更新itemType
      const ro = this.$route;
      let url = ro.path;
      url = url + "?";
      url = url + "itemType=" + e;
      this.$router.push(url);
    },

    brandchange(e) {
      this.getBrandbyPid(e);
      this.cid = e;
      const ro = this.$route;
      let url = ro.path;
      url = url + "?";
      url = url + "cid=" + e;
      this.$router.push(url);
    },

    getBrand() {
      brand().then((res) => {
        this.menucate = res.data;
      });
    },

    getBrandbyPid(id) {
      brandbypid({ parent_id: id }).then((res) => {
        this.currentCateList = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.productlist {
  display: flex;
  flex-wrap: wrap;
  padding-top: 2em;
  min-height: 500px;
}

.product {
  width: 20%;
  padding: 15px;
  box-sizing: border-box;

  .pt {
    width: 100%;

    .ptbg {
      padding: 15px;
      cursor: pointer;
      position: relative;

      .cover {
        width: 100%;
      }

      .mask {
        width: 100%;
        box-sizing: border-box;
        padding: 15px;
        position: absolute;
        left: 0;
        top: 0;
        background-color: var(--bs-tertiary-color);
      }
    }
  }

  .pb {
    padding: 0 15px;

    .title {
      font-size: 0.8rem;
      font-weight: bold;
      border-bottom: solid 1px var(--bs-border-color);
      padding: 6px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .price {
      font-size: 0.6rem;
      font-weight: 400;
      padding: 6px 0;
    }

    .addcart {
      display: flex;
      justify-content: space-between;
      padding: 6px 0;

      .icontext {
        font-weight: 400;

        i {
          margin-right: 6px;
          font-size: 0.7rem;
        }

        span {
          font-size: 0.7rem;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .product {
    width: 25%;
  }
}

@media (max-width: 1280px) {
  .product {
    width: 33.33%;
  }
}
@media (max-width: 980px) {
  .product {
    width: 50%;
  }
}
</style>
